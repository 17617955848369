import React, { useState } from 'react';
import './App.css';
import Contacts from './Contacts';
import Login from './Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const redirectToHomePage = () => {
    window.location.href = 'https://crm.acumentransit.com';
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 onClick={redirectToHomePage} style={{ cursor: 'pointer' }}>Contacts Manager</h1>
      </header>
      {isAuthenticated ? <Contacts /> : <Login onLogin={handleLogin} />}
    </div>
  );
}

export default App;